var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "e-filters",
        {
          attrs: { searching: _vm.fetching },
          on: { search: _vm.filterData, reset: _vm.resetFiltersLocal },
        },
        [
          _c(
            "div",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "service-request-id",
                          name: "serviceRequestId",
                          label: _vm.$t("Id da solicitação"),
                          type: "text",
                        },
                        model: {
                          value: _vm.filters.id,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "id", $$v)
                          },
                          expression: "filters.id",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "service-request-status",
                          multiple: "",
                          label: _vm.$t("Status"),
                          placeholder: _vm.$t("Todos"),
                          type: "vue-select",
                          options: [
                            {
                              label: _vm.$t("ServiceRequest.Status.InProgress"),
                              value: "InProgress",
                            },
                            {
                              label: _vm.$t("ServiceRequest.Status.Rejected"),
                              value: "Rejected",
                            },
                            {
                              label: _vm.$t("ServiceRequest.Status.Completed"),
                              value: "Completed",
                            },
                            {
                              label: _vm.$t("ServiceRequest.Status.Canceled"),
                              value: "Canceled",
                            },
                          ],
                        },
                        model: {
                          value: _vm.filters.status,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "status", $$v)
                          },
                          expression: "filters.status",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "period",
                          type: "date-range-picker",
                          label: _vm.$t("Solicitado em"),
                        },
                        model: {
                          value: _vm.filters.rangeDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "rangeDate", $$v)
                          },
                          expression: "filters.rangeDate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("e-user-search", {
                        attrs: {
                          id: "user_id",
                          label: _vm.$t("Usuário"),
                          name: "user_id",
                        },
                        model: {
                          value: _vm.filters.userId,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "userId", $$v)
                          },
                          expression: "filters.userId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("e-store-combo", {
                        attrs: { id: "order-store_id", required: false },
                        model: {
                          value: _vm.filters.storeId,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "storeId", $$v)
                          },
                          expression: "filters.storeId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "b-card-actions",
        {
          attrs: { "action-refresh": "", "show-loading": _vm.fetching },
          on: { refresh: _vm.getData },
        },
        [
          _c(
            "div",
            { attrs: { slot: "title" }, slot: "title" },
            [
              _c("e-page-size-selector", {
                attrs: { "per-page": _vm.paging.pageSize },
                on: { change: _vm.pageSizeChange },
              }),
            ],
            1
          ),
          _c("b-table", {
            staticClass: "bordered",
            attrs: {
              id: "service-request-table",
              "show-empty": "",
              responsive: "",
              striped: "",
              "empty-text": _vm.getEmptyTableMessage(
                _vm.$tc("Service Request")
              ),
              fields: _vm.fields,
              items: _vm.serviceRequests,
              "no-local-sorting": "",
              "sort-by": _vm.sorting.sortBy,
              "sort-desc": _vm.sorting.sortDesc,
            },
            on: {
              "update:sortBy": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sort-by": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sortDesc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "update:sort-desc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "sort-changed": _vm.getData,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(action)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c("e-grid-actions", {
                      attrs: {
                        "show-update": false,
                        "show-delete": false,
                        "show-read-only": _vm.$can("Read", "ServiceRequest"),
                        buttons:
                          item.status == "InProgress"
                            ? [
                                {
                                  icon: "x-circle",
                                  variant: "danger",
                                  title: _vm.$t("Cancelar Solicitação"),
                                  event: "service-request-cancel",
                                },
                              ]
                            : [],
                      },
                      on: {
                        "read-only": function ($event) {
                          return _vm.onDetailServiceRequest(item)
                        },
                        "service-request-cancel": function ($event) {
                          return _vm.onCancelServiceRequest(item)
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c(
            "b-row",
            { staticClass: "pt-1" },
            [
              _c(
                "b-col",
                [
                  _c("e-pagination-summary", {
                    attrs: {
                      "current-page": _vm.paging.currentPage,
                      "per-page": _vm.paging.pageSize,
                      total: _vm.paging.rowCount,
                      "total-on-page": _vm.paging.rowsInCurrentPage,
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                [
                  _c("b-pagination", {
                    attrs: {
                      align: "right",
                      "total-rows": _vm.paging.rowCount,
                      "per-page": _vm.paging.pageSize,
                      "aria-controls": "service-request-table",
                    },
                    on: { change: _vm.pageChange },
                    model: {
                      value: _vm.paging.currentPage,
                      callback: function ($$v) {
                        _vm.$set(_vm.paging, "currentPage", $$v)
                      },
                      expression: "paging.currentPage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.$can("Create", "ServiceRequest")
        ? _c("fab", {
            attrs: { "main-tooltip": _vm.$t("Adicionar Solicitação") },
            on: { click: _vm.onCreateServiceRequest },
          })
        : _vm._e(),
      _c("div", { staticClass: "pb-1" }),
      _c("e-modal-service-request-cancelation", {
        ref: "modalCancelation",
        on: {
          "after-confirm": function ($event) {
            return _vm.$router.push({ name: "service-request-list" })
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }